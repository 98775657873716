@import '~@/assets/scss/main.scss';

#enable-ebk-back-issues-card {
    padding: 1.25rem;
    border-radius: $border-radius;

    border: none;

    width: 20rem;
    height: 30rem;

    .text {
        @extend .font-raleway, .text-white;
        @extend .mt-2;
        font-size: 1.25rem;
        font-weight: 600;
    }
}
