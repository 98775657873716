@import '~@/assets/scss/main.scss';

.text-theme {
    color: v-bind(themeColorHex);
}

.activity-row {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem 2rem;

    div {
        min-width: 5rem;
        max-width: 8rem;
        text-align: center;
    }

    justify-content: center;
}

.activity-label {
    margin-top: 0.25rem;
    font-size: 1rem;
    @extend .font-raleway;
    @extend .text-black;
    @extend .font-weight-bold;
}
